.mat-dialog-container {
    border-radius: 10px;
    padding: 1em;

    @media screen and (max-width: 767px) {
        overflow: hidden;
    }

    @media screen and (min-width: 768px) {
        .formcontainer {
            min-height: 15.875em;
            overflow: hidden;
        }

        overflow: hidden;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 34.65em;
    }

    .formcontainer {
        @media screen and (min-width: 1200px) {
            width: 41.5em;
        }
    }
}


.mat-dialog-content {
    padding: 1em;
    font-family: 'Avenir-Roman', sans-serif;
    font-size: 1em;
    color: map-get($cartus-palette, 'cartus-gray');
    line-height: 1.5625em;

    .mat-icon {
        color: map-get($cartus-palette, 'cartus_dark_accent_blue');
    }

    p:first-of-type {
        margin: 0em;
    }

    @media screen and (min-width: 768px) {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.mat-dialog-actions {
    margin-bottom: 0;
    padding-top: 0.2em;
}


.button-icon {
    font-size: 2em;
    padding: 0.1em;
    margin: 0 0 0 0.25em;
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {

        .mat-icon {
            color: map-get($cartus-palette, 'cartus_light_gray');
        }

        cursor: pointer;
    }
}