@import '../sass/cartus-palette.scss';
@import '../sass/app-theme.scss';
@import '../sass/mixin.scss';
@import '../sass/material/mat-table.scss';
@import '../sass/material/dialog.scss';


.small-text {
  font-size: 0.75em;
  font-family: 'Avenir-Book', sans-serif;
  color: map-get($cartus-palette, 'cartus_charcoal_gray');
  text-align: left;
  line-height: 1em;

  &.inline-link {
    color: map-get($cartus-palette, 'cartus_accent_blue');

    &:hover {
      color: map-get($cartus-palette, 'cartus_dark_accent_blue');
      text-decoration: underline;
      background-color: map-get($cartus-palette, 'cartus_hover_tint');
    }
  }
}

.body-copy {
  font-size: 1em;
  font-family: 'Avenir-Book', sans-serif;
  color: map-get($cartus-palette, 'cartus_charcoal_gray');
  line-height: 1.5em;
  text-align: left;

  &.inline-link {
    color: map-get($cartus-palette, 'cartus_accent_blue');

    &:hover {
      color: map-get($cartus-palette, 'cartus_dark_accent_blue');
      text-decoration: underline;
      background-color: map-get($cartus-palette, 'cartus_hover_tint');
    }
  }
}


/*Mat Form Input Fields Common style*/
.mat-form-input {
  .mat-form-field {
    min-width: 400px;
    margin-bottom: 0.85em;
    color: map-get($cartus-palette, 'cartus_light_gray');
    font-family: 'Avenir-Book', sans-serif;

    @include media-down(desktop) {
      min-width: 300px;
    }
  }
}

.mat-error {
  color: map-get($cartus-palette, 'cartus_error_txt') !important;
  font-family: 'Avenir-Book', sans-serif;
  font-size: 100%;
}

/*anchor tag styles*/
.link-style {
  a {
    text-decoration: none;
    color: map-get($cartus-palette, 'cartus_accent_blue');
    font-family: 'Avenir-Roman', sans-serif;
    margin-bottom: 1.5em;

    &:hover,
    &:focus {
      color: map-get($cartus-palette, 'cartus_dark_accent_blue');
      background: map-get($cartus-palette, 'cartus_hover_tint');
      text-decoration: underline;
    }
  }
}

a {
  text-decoration: none;
  color: map-get($cartus-palette, 'cartus_accent_blue');
  font-family: 'Avenir-Roman', sans-serif;
  margin-bottom: 1em;

  &.small-text,
  .small-text {
    color: map-get($cartus-palette, 'cartus_accent_blue');
    text-align: unset;
    line-height: unset
  }

  &:hover,
  &:focus {
    color: map-get($cartus-palette, 'cartus_dark_accent_blue');
    background: map-get($cartus-palette, 'cartus_hover_tint');
    text-decoration: underline;
  }
}

h1 {
  font-size: 1.5em;
  font-family: 'Avenir-Medium', sans-serif;
  color: map-get($cartus-palette, 'cartus_charcoal_gray');
  font-weight: normal;
  margin: unset;
}

/*checkbox common style*/
.checkbox-block {
  margin-bottom: 1.5em;

  .mat-checkbox {
    color: map-get($cartus-palette, 'cartus_charcoal_gray');
    font-family: 'Avenir-Book', sans-serif;

    .mat-checkbox-frame {
      border-radius: 4px;
    }
  }
}

/* Panel background */
.panel-bg {
  background-color: map-get($cartus-palette, 'cartus_form_bg');
  box-shadow: 0px 2px 2px map-get($cartus-palette, 'cartus_border_shadow');
  border: 1px solid map-get($cartus-palette, 'cartus_border_light_gray');
}

/*aligning content to center */
.btn-align-center {
  display: flex;
  justify-content: center;
}

/* clear defaults */
.clean {
  padding: 0;
  margin: 0;
}

.FooterAlign {
  position: relative;

  .leftPanel {
    height: 100%;
  }
}